import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import {StaticImage} from "gatsby-plugin-image"
import Figure from "../components/figure"

export default function Ddom() {
    const seo = {
        title: "Dzienny Dom Opieki Medycznej im. Św. Jana w Dukli - Przychodnia specjalistyczna Medicus-Dukla",
        description: "Dzienny Dom Opieki Medycznej im. “Św. Jana w Dukli” Dzienny Dom Opieki Medycznej jest dedykowany seniorom, a także innym osobom potrzebującym dodatkowej opieki medycznej i pomocy w powrocie do zdrowia. Oferujemy różne formy terapii i leczenia. W ramach naszej placówki pacjenci pozostają pod opieką doświadczonej kadry lekarzy i pielęgniarek. Dla kogo przeznaczona jest nasza oferta: …",
        url: "https://medicusdukla.pl/ddom"
    }
    return (
        <Layout marginTop={'8rem'}>
            <SEO
                title={seo.title}
                description={seo.description}
                url={seo.url}
            />
            <div data-g="container" className="u-text-center">
                <div className="projects-logos">
                    <StaticImage className="a-img" src="../images/ddom/loga.png"
                                 alt="fundusze europejskie, rzeczpospolita polska, podkarpackie, unia europejska"
                                 formats={['auto', 'webp', 'avif']}/>

                </div>
                <br/>
                <br/>
            </div>
            <section className="o-ddom" data-g="container grid">
                <div data-g="4 6@md">
                    <h1 className="a-heading-medium">Dzienny Dom Opieki Medycznej im. “Św. Jana w Dukli”</h1>
                    <p style={{maxWidth: "450px"}}>
                        Dzienny Dom Opieki Medycznej jest dedykowany seniorom,
                        a także innym osobom potrzebującym dodatkowej opieki medycznej i pomocy w powrocie do zdrowia.
                        Oferujemy
                        różne formy terapii i leczenia. W ramach naszej placówki pacjenci pozostają pod opieką
                        doświadczonej kadry
                        lekarzy i pielęgniarek.
                        <br/><br/><br/>
                    </p>
                    <h2 className="a-heading-small">Dla kogo przeznaczona jest nasza oferta?</h2>
                    <br/>
                    <Figure src="/images/ddom/bed.svg"
                            text="Osoby wracające do zdrowia bezpośrednio po pobycie w szpitalu"/>
                    <Figure src="/images/ddom/senior.svg" text="Seniorzy"/>
                    <Figure src="/images/ddom/difficulties.svg"
                            text="Osoby mają trudności w samodzielnym wypełnianiu codziennych czynności"/>
                    <Figure src="/images/ddom/functioning.svg"
                            text="Osoby mają trudności w samodzielnym funkcjonowaniu"/>
                </div>

                <div data-g="4 offset-8@md 5@md">
                    <StaticImage className="a-img" src="../images/ddom/hero.png" alt="zdjęcie pacjenta" quality={80}
                                 formats={['auto', 'webp', 'avif']}/>
                    <div className="o-ddom__documents">
                        <h2 className="a-heading-small">Dokumenty do pobrania</h2>

                        {[
                            {
                                title: "Regulamin Organizacyjny",
                                file: "Regulamin organizacyjny DZIENNY DOM OPIEKI Medicus"
                            }
                        ].map(({title, file}, key) => (
                            <div className="document">
                                <span>{title}</span>
                                <span>
                <a href={`/uploads/${file}.pdf`} target="_blank" rel="noopener noreferrer">pdf</a> &nbsp;
              </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div data-g="4 6@md">
                    <h2 className="a-heading-small">W ofercie</h2>
                    <p>
                        Do podstawowych kategorii świadczeń zdrowotnych realizowanych w ramach domu opieki medycznej
                        należą:
                        <ul>
                            <li>opieka pielęgniarska, w tym edukacja pacjenta dotycząca samoopieki i samopielęgnacji;
                            </li>
                            <li>doradztwo w doborze odpowiednich wyrobów medycznych;</li>
                            <li>usprawnianie ruchowe;</li>
                            <li>stymulacja procesów poznawczych;</li>
                            <li>terapia zajęciowa;</li>
                            <li>przygotowanie rodziny i opiekunów pacjenta do kontynuacji opieki (działania
                                edukacyjne).
                            </li>
                        </ul>
                    </p>
                    <p>
                        Koszt jednodniowego pobytu wynosi 150,00 zł
                    </p>
                    <p>
                        Koszt miesięcznego pobytu wynosi 2.500,00 zł
                    </p>
                </div>
            </section>
            <div data-g="container" className="u-text-center">
                <br/><br/><br/><br/>
                <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz"/>
            </div>
        </Layout>
    )
}
